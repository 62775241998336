<template>
  <div>
    <b-badge
      v-if="Number(data.item.stock) <= Number(data.item.tramoDesdeRojo)"
      variant="danger"
    >
      {{ Number(data.item.stock) }}
    </b-badge>
    <b-badge
      v-if="
        Number(data.item.stock) <= Number(data.item.tramoDesdeAmarillo) &&
        Number(data.item.stock) > Number(data.item.tramoDesdeRojo)
      "
      variant="warning"
    >
      {{ Number(data.item.stock) }}
    </b-badge>
    <b-badge
      v-if="
        Number(data.item.stock) <= Number(data.item.tramoDesdeVerde) &&
        Number(data.item.stock) > Number(data.item.tramoDesdeAmarillo)
      "
      variant="success"
    >
      {{ Number(data.item.stock) }}
    </b-badge>
    <b-badge
      v-if="Number(data.item.stock) > Number(data.item.tramoDesdeVerde)"
      variant="secondary"
    >
      {{ Number(data.item.stock) }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      variant: null,
    }
  },
}
</script>
